<template>
<v-row>
  <v-col cols="12" md="10">
    <v-text-field label="* Descrição"
      dense
      :rules="[
          this.$validators.string.required,
                v => this.$validators.string.greaterThanOrEquals(v, 1),
          v => this.$validators.string.lessThanOrEquals(v, 200)
      ]"
      v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12" md="2">
    <v-text-field
      label="* Ordem"
      dense
      :rules="[
          this.$validators.number.required,
          v => this.$validators.number.greaterThanOrEquals(v, 0)
      ]"
      v-model="value.ordem" type="number"/>     
  </v-col>  
</v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";

export default {
  name: "DocumentoForm",
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      error: null,
    }
  },
  watch: {
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>